<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="credit" backlink="/credit/have-credit" />
      <div class="content">
        <div class="content__container">
          <TextBlock :id="16" /><br>
          <div class="service__container">
            <button
              type="button"
              class="service service_credit service_credit_1"
              @click="saveAndGo(1)"
            >
              <div class="service__title">{{ "refinance_credit" | localize }}</div>
            </button>
            <button
              type="button"
              class="service service_credit service_credit_2"
              @click="saveAndGo(0)"
            >
              <div class="service__title">{{ "continue_new_credit" | localize }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import TextBlock from '@/components/Text.vue'

export default {
  name: 'Credit_ref_credit',
  components: { Backlink, TextBlock },
  methods: {
    saveAndGo (value) {
      if (value === 1) {
        // Рефинансировать кредит
        const credit = this.$cookies.get('credit') || {}
        const refCredit = this.$cookies.get('refCredit') || {}
        refCredit.clients = credit.clients || []
        this.$cookies.set('refCredit', refCredit)
        this.$router.push('/refinance-credit/registration')
      } else {
        // Продолжить обычный кредит
        this.$router.push('/credit/amount-credits')
      }
    }
  }
}
</script>
